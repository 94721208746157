/* changing the default prime react cards padding  */
/* .p-card .p-card-body {
  padding: 0.5rem;
} */

@media (max-width: 480px) {
    .p-card .p-card-body,
.p-card .p-card-body {
    padding: 0;
    border-radius: 0;
}
 }

 .p-card .p-card-content {
    padding:0;
}

.p-card .p-card-body {
    padding: 0;
}

@media (max-width: 600px) {
    .p-card .p-card-body,
    .p-card .p-card-body {
        padding: 0.5rem;
        border-radius: 0;
    }
}

.p-card {
    border-radius: 0;
}

@media (max-width: 480px) {
    .layout-wrapper .layout-main-container {
        margin-left: 0;
        padding-left: 0 !important;
    }

    .layout-main-container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        justify-content: space-between;
        padding: 7rem 0 2rem 0;
        /* padding: 7rem 2rem 2rem 4rem; */
        transition: margin-left 0.2s;
    }
}


/* remove panel padding  */
.p-panel .p-panel-content {
    padding: 0.1rem !important;
}

/* remove underline on links  */
a:-webkit-any-link{
    text-decoration: none;
}


/* helps to increase test in input fields */
.auth-form .p-inputtext {
    font-size: 2rem;
 }


 /* active side bar menue border case active or clicked */

 .active-sidebar-item-clicked {
    outline: 0 none;
    outline-offset: 0;
    transition: box-shadow 0.2s;
    box-shadow: inset var(--focus-ring);
 }



 /*============ custom scrollbar =========== */
::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }